<template>
    <div id="dc-settings">
        <h3>Settings</h3>
        <!-- <hr /> -->
        <!-- <b-button class="dc-button-primary" @click="quickbooksAuthUri">Authorization</b-button> -->
        <hr />
        <b-button class="dc-button-primary" @click="refreshAccessToken">Refresh Token</b-button>
        <hr />

        <!-- <b-button class="dc-button-primary" @click="getCustomerInfo">Get Customer Info</b-button>
        <hr /> -->

        <!-- <b-button class="dc-button-primary" @click="createNewCustomer">Create new Customer</b-button> -->
        <!-- <hr /> -->

        <!-- <b-button class="dc-button-primary" @click="createAllPasswords">Create All Passwords</b-button> -->
        <!-- <b-button class="dc-button-primary" @click="createCustomerPassword">Create Customer Password</b-button> -->
        <!-- <b-button class="dc-button-primary" @click="getQuickbooksAllProducts">Get All Products</b-button> -->
        <!-- <b-button class="dc-button-primary" @click="getQuickbooksCustomer">Get Customer Info</b-button> -->
        <!-- <hr /> -->
        <!-- <b-button class="dc-button-primary" @click="makeCustomersInactive">Customers In-active</b-button> -->
        <!-- <b-button class="dc-button-primary" @click="getQuickbooksAllCustomers">Get All Customers</b-button> -->
        <!-- <b-button class="dc-button-primary" @click="getInvoiceInfo">Get Invoice Info</b-button> -->
        <!-- <b-button class="dc-button-primary" @click="getAllInvoices">Get All Invoice</b-button> -->

        <!-- <b-card no-body id="dc-qrcodes-card">
            <b-tabs card>
                <b-tab title="Quantity">
                    <div id="dc-quantity-container">
                        <h2>QR Codes</h2>

                        <table>
                            <tr>
                                <th>Quantity</th>
                                <th>Created</th>
                                <th>QRCode</th>
                                <th>Action</th>
                            </tr>
                            <tr class="dc-quantity-qrcode-container" v-for="qrcode in qrcodesQuantity" :key="qrcode.id">
                                <td>{{ qrcode.content }}</td>
                                <td>{{ qrcode.createdAtFormatted }}</td>
                                <td class="dc-quantity-qrcode-placeholder"></td>
                                <td><b-button @click="onClickShowPrintQrcode(qrcode)">Print</b-button></td>
                            </tr>
                        </table>
                    </div>
                </b-tab>
            </b-tabs>
        </b-card> -->

        <div id="dc-settings-footer">
            <span>DirtCheap POS - v {{ version }}</span>
            <span>© Copyright 2021 | Dirt Cheap | All Rights Reserved</span>
        </div>

        <!-- Modal - Product -->
        <!-- <b-modal id="dc-modal-product-qrcode" ref="dc-modal-product-qrcode" title="New Product QRCode" centered hide-footer>
            <b-form-select v-model="selectedProduct" :options="productOptions"></b-form-select>
            <b-button class="mt-3" block @click="onClickCreateProductQRCode">Create</b-button>
        </b-modal> -->

        <!-- Modal - Quantity -->
        <b-modal id="dc-modal-quantity-qrcode" ref="dc-modal-quantity-qrcode" title="New Quantity QRCode" centered hide-footer>
            <b-form-input v-model="createQRQuantity" required placeholder="Enter Quantity"></b-form-input>
            <b-button class="mt-3" block @click="onClickCreateQuantityQRCode">Create</b-button>
        </b-modal>

        <!-- Modal - Print Qrcode -->
        <b-modal id="dc-modal-print-qrcode" ref="dc-modal-print-qrcode" title="Print QRCode" centered hide-footer>
            <vue-html2pdf :filename="printQrcodeQuantity" pdf-format="a4" pdf-orientation="portrait" :show-layout="true" :float-layout="false" :useCORS="true" :letterRendering="true" :enable-download="true" :preview-modal="false" :paginate-elements-by-height="750" :pdf-quality="2" :manual-pagination="false" pdf-content-width="100%" ref="html2Pdf">
                <section style="width: 563px; height: 750px; display:flex; flex-direction: row; align-items:center; justify-content:center" slot="pdf-content">
                    <div id="dc-print-qrcode-placeholder"></div>
                    <div class="dc-info" style="display: flex; flex-direction: column; align-items: start; padding: 10px">
                        <span style="font-size: 16px; width: 120px; text-align: left"
                            ><b>Quantity: {{ printQrcodeQuantity }}</b></span
                        >
                    </div>
                </section>
            </vue-html2pdf>
            <b-button class="mt-3" block @click="onClickPrintQRCode">Print</b-button>
        </b-modal>
    </div>
</template>

<script>
import _ from "lodash";
import APICalls from "./APICalls";
import axios from "axios";
import moment from "moment";
import QrcodeAPICalls from "./../qrcode/APICalls";
// import CustomerAPICalls from "./../customer/APICalls";
import ProductAPICalls from "./../product/APICalls";
import QuickbooksAPICalls from "./../quickbooks/APICalls";
import qrcodeGenerator from "qrcode-generator";
import VueHtml2pdf from "vue-html2pdf";
import * as Endpoints from "./../../constant/api-endpoints";
// import customers from "./../customer/customers.json";
// import dirtcheap_customers from "./../customer/dirtcheap_customers.json";

export default {
    name: "Settings",
    components: {
        VueHtml2pdf,
    },
    data() {
        return {
            createQRQuantity: null,
            productsList: [],
            productOptions: [],
            printQrcode: {},
            printQrcodeQuantity: "",
            selectedProduct: null,
            qrcodesProduct: [],
            qrcodesQuantity: [],
            allCustomers: [],
            version: "2.0.1",
        };
    },
    methods: {
        generateQrcodes() {
            let typeNumber = 0;
            let errorCorrectionLevel = "L";
            setTimeout(() => {
                // For - Product
                // _.each(this.qrcodesProduct, (qrcode, i) => {
                //     let qr = qrcodeGenerator(typeNumber, errorCorrectionLevel);
                //     qr.addData(qrcode.content);
                //     qr.make();
                //     document.getElementsByClassName("dc-product-qrcode-placeholder")[i].innerHTML = qr.createImgTag();
                // });

                // For - Quantity
                _.each(this.qrcodesQuantity, (qrcode, i) => {
                    let qr = qrcodeGenerator(typeNumber, errorCorrectionLevel);
                    qr.addData(qrcode.content);
                    qr.make();
                    document.getElementsByClassName("dc-quantity-qrcode-placeholder")[i].innerHTML = qr.createImgTag();
                });
            });
        },
        onClickCreateProductQRCode() {
            console.log("Create Product QRCode");
            this.createProductQrcode();
        },
        onClickCreateQuantityQRCode() {
            console.log("Create Quantity QRCode");
            this.createQuantityQrcode();
        },
        onClickPrintQRCode() {
            this.$refs.html2Pdf.generatePdf();
        },
        onClickShowPrintQrcode(qrcode) {
            this.printQrcodeQuantity = "";
            this.$refs["dc-modal-print-qrcode"].show();

            setTimeout(() => {
                let contentJson = JSON.parse(qrcode.content);
                this.printQrcodeQuantity = contentJson.quantity;

                // Print
                let typeNumber = 0;
                let errorCorrectionLevel = "L";
                let qr = qrcodeGenerator(typeNumber, errorCorrectionLevel);
                qr.addData(JSON.stringify(qrcode.content));
                qr.make();
                let qrcodeImg = qr.createImgTag(4);
                document.getElementById("dc-print-qrcode-placeholder").innerHTML = qrcodeImg;
            }, 500);
        },
        async createNewCustomer() {
            // let finalCustomers = [];
            // let totalFound = 0;
            // try {
            //     for (let index in dirtcheap_customers) {
            //         let currentCustomer = dirtcheap_customers[index];
            //         let currentName = _.get(currentCustomer, "Company Name");
            //         let foundOne = _.find(customers, (obj) => {
            //             return obj.name === currentName;
            //         });
            //         if (foundOne) totalFound++;
            //         let newCustomer = {
            //             id: _.get(foundOne, "id"),
            //             name: _.get(foundOne, "name") ? _.get(foundOne, "name") : _.get(currentCustomer, "Company Name"),
            //             company: _.get(foundOne, "name") ? _.get(foundOne, "name") : _.get(currentCustomer, "Company Name"),
            //             email: _.get(foundOne, "email"),
            //             phone: _.get(foundOne, "phone"),
            //             truck: _.get(foundOne, "truck"),
            //             address: _.get(foundOne, "address"),
            //             productsId: _.get(currentCustomer, "Products"),
            //             quickbooksId: _.get(currentCustomer, "Customer ID").toString(),
            //             version: "1",
            //             status: "active",
            //             standing: "1",
            //         };
            //         // console.log(currentName, newCustomer);
            //         if (foundOne) finalCustomers.push(newCustomer);
            //         let res = await CustomerAPICalls.createCustomer(newCustomer);
            //         console.log("SUCCESS", res);
            //     }
            //     // console.log(JSON.stringify(finalCustomers, null, 2));
            //     console.log(dirtcheap_customers.length, totalFound);
            // } catch (e) {
            //     console.log("Create New Customer", e);
            // }
        },
        async getCustomerInfo() {
            try {
                let customerInfo = await APICalls.getCustomerInfo(47558);
                console.log(customerInfo.Customer);
            } catch (e) {
                console.log(e);
            }
        },
        async createProductQrcode() {
            let params = {
                content: {
                    productId: this.selectedProduct,
                },
                qrcodeType: 2,
            };
            let response = await QrcodeAPICalls.createQrcode(params);
            console.log(response);
        },
        async createQuantityQrcode() {
            let params = {
                content: {
                    quantity: this.createQRQuantity,
                },
                qrcodeType: 3,
            };
            let response = await QrcodeAPICalls.createQrcode(params);
            console.log(response);
        },
        async getQuickbooksAllProducts() {
            axios
                .get(Endpoints.quickbooks_product_endpoint, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
                })
                .then((res) => {
                    console.log("Quickbooks All Products:", JSON.stringify(res.data, null, 2));
                    console.log(res.data.QueryResponse.Item.length);
                })
                .catch((e) => {
                    console.log("Quickbooks All Products Error:", e.response);
                });
        },
        // Get Customer
        async getQuickbooksCustomer() {
            axios
                .get(Endpoints.quickbooks_get_customer_endpoint, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
                })
                .then((res) => {
                    console.log("Quickbooks Customer:", res.data);
                })
                .catch((err) => {
                    console.log("Quickbooks Customer Error:", err);
                });
        },
        async getProducts() {
            let products = await ProductAPICalls.getProducts();
            this.productsList = products;
        },
        async getQrcodes() {
            let qrcodes = await QrcodeAPICalls.getQrcodes();
            this.qrcodesProduct = [];
            this.qrcodesQuantity = [];

            _.each(qrcodes, (qrcode) => {
                if (qrcode.qrcodeType && qrcode.qrcodeType.id === 2) {
                    let createdAtFormatted = moment(qrcode.created).format("ll");
                    qrcode.createdAtFormatted = createdAtFormatted;
                    this.qrcodesProduct.push(qrcode);
                } else if (qrcode.qrcodeType && qrcode.qrcodeType.id === 3) {
                    let createdAtFormatted = moment(qrcode.created).format("ll");
                    qrcode.createdAtFormatted = createdAtFormatted;
                    this.qrcodesQuantity.push(qrcode);
                }
            });
            this.generateQrcodes();
        },
        // Create
        async createQuickbooksAccessToken() {
            let config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
                params: {
                    code: this.$route.query.code,
                },
            };
            console.log(config);
            axios
                .get(Endpoints.quickbooks_create_token_endpoint, config)
                .then((res) => {
                    console.log("Quickbooks Token", res.data);
                })
                .catch((err) => {
                    console.log("Quickbooks Token err", err);
                });
        },
        // Make Customers Inactive
        async makeCustomersInactive() {
            let customersId = [];

            let successResponse = [];
            let errorReponse = [];

            for (let index in customersId) {
                try {
                    let id = customersId[index];
                    console.log("Customer Id:", id);
                    await APICalls.makeCustomerInactive(id);
                    console.log(customersId.length, parseInt(index));
                    successResponse.push(id);
                } catch (e) {
                    console.log(e);
                    errorReponse.push(e);
                }
            }

            console.log(customersId);
            console.log(successResponse);
            console.log(errorReponse);
        },
        // Refresh
        async refreshAccessToken() {
            axios
                .get(Endpoints.quickbooks_refresh_token_endpoint, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
                })
                .then((res) => {
                    this.$toasted.show("Refresh Token Success !", { position: "bottom-center", className: "dc-toast-success", duration: 5000 });
                    console.log("Refresh Access Token", res.data);
                })
                .catch((err) => {
                    console.log("Refresh Access Token", err);
                });
        },
        // Get Invoice Info
        async getInvoiceInfo() {
            let invoiceId = 300734;
            let response = await QuickbooksAPICalls.getInvoice(invoiceId);
            console.log(response);
        },
        // All Customers
        async getQuickbooksAllCustomers() {
          let range = [1, 1001, 2001, 3001, 4001, 5001, 6001, 7001, 8001, 9001, 10001, 11001, 12001, 13001, 14001, 15001, 16001, 17001, 18001, 19001, 20001];
            let status = "active"; // active, inactive, all

            for (let index in range) {
                try {
                    let startPosition = range[index];
                    console.log("Position:", startPosition, status);
                    let response = await APICalls.getCustomers(startPosition, status);
                    let customerRes = _.get(response, "QueryResponse.Customer");
                    if (customerRes) {
                        this.allCustomers = this.allCustomers.concat(customerRes);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            console.log(JSON.stringify(this.allCustomers, null, 2));
            console.log(`Total Customers - ${status}`, this.allCustomers.length);
        },
        // Url
        async quickbooksAuthUri() {
            axios
                .get(Endpoints.quickbooks_get_auth_uri_endpoint, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
                })
                .then((res) => {
                    console.log("Auth Uri", res.data);
                    let url = res.data;
                    window.open(url, "_self");
                })
                .catch((err) => {
                    console.log("Auth Uri", err);
                });
        },
        async createAllPasswords() {
            // let maxIndex = 152;
            // for (let i = 1; i <= maxIndex; i++) {
            //     try {
            //         await QuickbooksAPICalls.createPassword(i);
            //     } catch (e) {
            //         console.log(e);
            //     }
            // }
        },
        async createCustomerPassword() {
            // let customerId = 126; // Triple L Lawn & Landscape ID
            // try {
            //     await QuickbooksAPICalls.createPassword(customerId);
            //     console.log("Success");
            // } catch (e) {
            //     console.log(e);
            // }
        },
    },
    watch: {
        productsList: function(val) {
            this.productOptions = [];
            this.productOptions.push({ value: null, text: "Select product" });
            _.each(val, (v) => {
                let option = {};
                option.value = v.id;
                option.text = v.name;
                this.productOptions.push(option);
            });
        },
    },
    mounted() {
        // this.getQrcodes();
        // this.getProducts();

        // For creating new quantity qrcodes
        // this.$refs["dc-modal-quantity-qrcode"].show();

        let code = this.$route.query.code;
        if (code) {
            console.log("Code found - Create Access Token");
            this.createQuickbooksAccessToken();
        } else {
            console.log("Node code found");
        }
    },
};
</script>

<style lang="scss">
#dc-settings {
    .dc-button-primary {
        width: 250px;
        margin-top: 20px;
    }

    table {
        width: 100%;

        th {
            padding: 10px;
            text-align: center;
        }

        td {
            text-align: center;

            img {
                width: 100px;
                height: 100px;
            }
        }
    }

    #dc-settings-footer {
        position: fixed;
        bottom: 0;
        left: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;
        margin-left: -80px;
    }
}
</style>
