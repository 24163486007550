import axios from "axios";

import { dirtcheap_quickbooks_make_customer_inactive_endpoint, quickbooks_get_customer_endpoint, quickbooks_get_all_customer_endpoint } from "./../../constant/api-endpoints";

let getCustomerInfo = function(id) {
    return new Promise((resolve, reject) => {
        let config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            params: {
                id: id,
            },
        };
        axios
            .get(quickbooks_get_customer_endpoint, config)
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let makeCustomerInactive = function(id) {
    return new Promise((resolve, reject) => {
        let config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            params: {
                id: id,
            },
        };
        axios
            .get(dirtcheap_quickbooks_make_customer_inactive_endpoint, config)
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let getCustomers = function(startPosition, status) {
    return new Promise((resolve, reject) => {
        let config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            params: {
                startPosition: startPosition,
                status: status,
            },
        };
        axios
            .get(quickbooks_get_all_customer_endpoint, config)
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

export default { makeCustomerInactive, getCustomerInfo, getCustomers };
