import axios from "axios";

import { qrcode_endpoint } from "./../../constant/api-endpoints";

let createQrcode = function(params) {
    return new Promise((resolve, reject) => {
        axios
            .post(qrcode_endpoint, params, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let deleteQrcode = function(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(qrcode_endpoint + "/" + id, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let editQrcode = function(id, params) {
    return new Promise((resolve, reject) => {
        axios
            .post(qrcode_endpoint + "/" + id, params, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let getQrcode = function(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(qrcode_endpoint + "/" + id, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let getQrcodes = function() {
    return new Promise((resolve, reject) => {
        axios
            .get(qrcode_endpoint, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

export default { createQrcode, deleteQrcode, editQrcode, getQrcode, getQrcodes };
